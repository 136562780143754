import { Divider, Form, Radio, TreeSelect, Skeleton } from "antd";
import React, { useEffect, useState, Suspense, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { fetchRegionsData, fetchLevelsData } from "../../utilData";
import { GeometryAccuracy } from "../PlotsOptions/GeometryAccuracy";

import { getFieldPath, getIsFieldChanged } from "../utils/utilsForm";

import LoadingSpinWithMessage from "../LoadingSpinWithMessage";
import _ from "lodash";
import { throwCustomErrorInvalidValueOfVariable } from "../utils/multiSourceUtils";
import { useDebug } from "../CustomHooks/debugHooks";

const FormItemsLocationsGlobal = (props) => {
  const intl = useIntl();
  const componentName = "FormItemsLocationsGlobal";

  // ==============================================
  // variables and functions from props and context
  // ==============================================

  const {
    formMode,
    analysisProject,
    analysisType,
    antDividerBorderTopColor,
    areAllFormFieldsJustFilledByCodeRef,
    changedField,
    configEdit,
    configView,
    parent,
    referenceGeoAreas,
    form,
    isLocationsBarPlotXVariable,
    parentPath,
    regionsFieldInitialValue,
    required,
    style,
  } = props;

  // ==============================
  // component specific variables
  // ==============================

  const formItemComponentName = "locations";
  const rootPath =
    parent === "DataAnalysisForm"
      ? getFieldPath(parentPath, [formItemComponentName]) // ['configEdit', 'locations']
      : parentPath
      ? parentPath
      : [];

  //configuration variables (derived from props)
  const dataCollectionMode = useMemo(() => {
    let mode = {
      regionsFields: true,
      levelsField: undefined,
      geometryAccuracyField: undefined,
    };
    switch (parent) {
      case "DataAnalysisForm":
        if (!analysisType) return;

        //levelsField
        if (
          ["ChoroplethMap"].includes(analysisType) ||
          isLocationsBarPlotXVariable
        ) {
          mode.levelsField = true;
        } else if (
          [
            "TimeSeries",
            "BubbleMap",
            "HeatMap",
            "SimplePointMap",
            "CategoricalPointMap",
          ].includes(analysisType)
        ) {
          mode.levelsField = false;
        }

        //geometryAccuracyField
        if (["BarPlot", "TimeSeries"].includes(analysisType)) {
          mode.geometryAccuracyField = true;
        } else if (
          [
            "BubbleMap",
            "ChoroplethMap",
            "HeatMap",
            "SimplePointMap",
            "CategoricalPointMap",
          ].includes(analysisType)
        ) {
          mode.geometryAccuracyField = false;
        }
        break;
      default:
      //do nothing
    }

    return mode;
  }, [analysisType, isLocationsBarPlotXVariable, parent]);

  // ===================================
  // other variables and local functions
  // ===================================

  const getFieldPathShort = (fieldName) => {
    if (Array.isArray(fieldName)) {
      return getFieldPath(rootPath, fieldName);
    } else if (typeof fieldName === "string") {
      return getFieldPath(rootPath, [fieldName]);
    } else {
      throwCustomErrorInvalidValueOfVariable(
        "fieldName",
        { value: fieldName },
        "getFieldPathShort",
        [
          "The type of the argument 'fieldName' is not 'array' or 'string'.",
          "The current type of 'fieldName' is:",
          `${typeof fieldName}`,
        ].join(" "),
        "FormItemsLocationsGlobal"
      );
    }
  };

  const isFieldChangedFunction = (fieldString) => {
    const fieldPath = getFieldPathShort(fieldString);
    return getIsFieldChanged(fieldPath, changedField.name);
  };

  const LoadingTreeDataRegionsMemo = () => {
    return (
      <LoadingSpinWithMessage
        customMessageId={
          "loading.FormItemLocationsGlobal.geoRegion.options.message"
        }
      />
    );
  };

  const LoadingTreeDataLevelsMemo = () => {
    return (
      <LoadingSpinWithMessage
        customMessageId={
          "loading.FormItemLocationsGlobal.levels.options.message"
        }
      />
    );
  };

  const resetFormFields = () => {
    const fieldsToResetLocations = ["regions", "regionsSelectionMode"];

    if (dataCollectionMode?.levelsField) {
      fieldsToResetLocations.push("levels");
    }

    if (["BarPlot", "TimeSeries"].includes(analysisType)) {
      fieldsToResetLocations.push("geometryAccuracy");
    }

    const fieldsToResetLocationsPaths = fieldsToResetLocations.map((field) =>
      getFieldPathShort(field)
    );

    form.resetFields(fieldsToResetLocationsPaths);

    if (["BarPlot", "TimeSeries"].includes(analysisType)) {
      form.setFieldValue(getFieldPathShort("geometryAccuracy"), 0.0001);
    }
  };

  const [referenceRegions, setReferenceRegions] = useState(referenceGeoAreas);
  useEffect(
    function useEffect_setReferenceRegions() {
      setReferenceRegions(referenceGeoAreas);
    },
    [referenceGeoAreas]
  );

  const resetComponent = (changedFieldName) => {
    //reset fields
    resetFormFields();

    //reset hooks: states, refs
    setSelectedRegions({ value: [], mode: "reference" });
    setSelectedLevels();
    setRegionsSelectionMode(
      form.getFieldValue(getFieldPathShort("regionsSelectionMode"))
    );

    setTreeDataRegions();
    setTreeDataLevels();
    treeDataRegionsMemoRef.current = undefined;
    treeDataLevelsMemoRef.current = undefined;
    setTreeExpandedKeysRegions(() => {
      const initExpandedKeys = [];

      return initExpandedKeys;
    });

    if (changedFieldName === "type") {
      setReferenceRegions(referenceGeoAreas);
    }
  };

  // ===========================
  // Fetch params
  // ===========================
  const [fetchRegionsStatus, setFetchRegionsStatus] = useState({
    description: "No regions data fetched yet!",
  });

  const [fetchLevelsStatus, setFetchLevelsStatus] = useState({
    description: "No levels data fetched yet!",
  });

  // ===========================
  // RegionsSelectionMode
  // ===========================

  const [regionsSelectionMode, setRegionsSelectionMode] = useState(
    form.getFieldValue(getFieldPathShort("regionsSelectionMode"))
  );

  //useEffect_init_regionsSelectionMode
  useEffect(function useEffect_init_regionsSelectionMode() {
    setRegionsSelectionMode(
      form.getFieldValue(getFieldPathShort("regionsSelectionMode"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //useEffect_update_regionsSelectionMode_byUserInteraction
  useEffect(
    function useEffect_update_regionsSelectionMode_byUserInteraction() {
      if (!changedField) return;

      if (isFieldChangedFunction("regionsSelectionMode")) {
        setRegionsSelectionMode(changedField.value);
      }
    },
    //eslint-disable-next-line
    [changedField]
  );

  // ===========================
  // Regions
  // ===========================
  const [selectedRegions, setSelectedRegions] = useState(() => {
    let value, mode;

    switch (parent) {
      case "DataAnalysisForm":
        if (formMode === "new") {
          value = [];
        } else if (formMode === "edit") {
          value = configEdit.locations.regions;
        } else if (formMode === "view") {
          value = configView ? configView.locations.regions : [];
        }
        break;
      default:
        if (formMode === "new") {
          value = [];
        } else if (formMode === "edit") {
          value = form.getFieldValue(getFieldPathShort("regions"));
        }
    }

    mode = value?.length > 0 ? "selection" : "reference";

    return { value: value, mode: mode };
  });
  const [treeDataRegions, setTreeDataRegions] = useState();
  const [treeExpandedKeysRegions, setTreeExpandedKeysRegions] = useState([]);

  const referenceRegionCodeFilters = useMemo(() => {
    let filter;
    if (["DataAnalysisForm", "SurveyFormsData"].includes(parent)) {
      if (!referenceRegions || referenceRegions.length === 0) return null;

      const values = referenceRegions.map((geoArea) => {
        return {
          regionCode: geoArea.regionCode,
          regionIdLevel: geoArea.idLevel,
          regionCodeLevelUp: geoArea.regionCodeLevelUp,
          regionName: geoArea.name,
        };
      });

      filter = {
        values: values,
        strategy: "regionTree",
        returnCase: "treeData",
      };
    } else {
      filter = {
        values: [],
        strategy: "all",
        returnCase: "treeData",
      };
    }

    return filter;
  }, [referenceRegions, parent]);

  const fetchFilter = useMemo(() => {
    if (referenceRegionCodeFilters === null) return null;

    const filters = {
      referenceRegionsFilter: referenceRegionCodeFilters.values,
      strategy: referenceRegionCodeFilters.strategy,
      parent: parent,
    };

    return filters;
  }, [referenceRegionCodeFilters, parent]);

  const getTDByValue = (value, treeData) => {
    const tD = treeData.find((tD) => tD.value === value);
    return tD;
  };

  const getErrorMsgForTreeDataMemo = () => {
    const errorMsg =
      `Something went wrong. Not implemented value for 'selectedRegions.mode': [${selectedRegions?.mode}] ` +
      `when getting 'treeDataMemo' for 'formMode': [${formMode}].`;

    return errorMsg;
  };

  const getTreeDataMemoForNewMode = (treeDataArg) => {
    let treeData = _.cloneDeep(treeDataArg);
    let errorMsg;

    switch (selectedRegions.mode) {
      case "selection":
        //filter treeData by siblings and parents
        const selectedTD = getTDByValue(selectedRegions.value[0], treeData);
        const selectedIdLevel = selectedTD?.regionIdLevel;
        const selectedLevelUp = selectedTD?.regionCodeLevelUp;

        //update selection hierarchy categories
        treeData.forEach((tD) => {
          tD.isParentOfSelection =
            tD.regionIdLevel < selectedIdLevel &&
            selectedLevelUp.startsWith(tD.regionCode);
          tD.isSiblingOfSelection =
            tD.regionCodeLevelUp === selectedLevelUp &&
            !selectedRegions.value.includes(tD.value);
          tD.isSelectionItSelf =
            tD.regionCodeLevelUp === selectedLevelUp &&
            selectedRegions.value.includes(tD.value);
        });

        treeData = treeData.filter((tD) => {
          return (
            tD.isParentOfSelection ||
            tD.isSelectionItSelf ||
            tD.isSiblingOfSelection
          );
        });

        //set leaf all node with selectedIdLevel
        treeData.forEach((tD) => {
          //set isLeaf
          tD.isLeaf = tD.regionIdLevel === selectedIdLevel;
          tD.selectable =
            regionsSelectionMode === "selection"
              ? tD.isSiblingOfSelection || tD.isSelectionItSelf
              : false;
          tD.disabled =
            regionsSelectionMode === "selection"
              ? !tD.isSiblingOfSelection && !tD.isSelectionItSelf
              : true;
        });
        break;
      case "reference":
        treeData = _.cloneDeep(treeDataRegions);
        break;
      default:
        errorMsg = getErrorMsgForTreeDataMemo();
        console.log(errorMsg);
        throw new Error(errorMsg);
    }

    return treeData;
  };

  const getTreeDataMemoForEditMode = (treeDataArg) => {
    let treeData = _.cloneDeep(treeDataArg);
    let errorMsg;

    switch (selectedRegions.mode) {
      case "selection":
        //filter treeData by siblings and parents
        const selectedTD = getTDByValue(selectedRegions.value[0], treeData);
        const selectedIdLevel = selectedTD?.regionIdLevel;
        const selectedLevelUp = selectedTD?.regionCodeLevelUp;

        //update selection hierarchy categories
        treeData.forEach((tD) => {
          tD.isParentOfSelection =
            tD.regionIdLevel < selectedIdLevel &&
            selectedLevelUp.startsWith(tD.regionCode);
          tD.isSiblingOfSelection =
            tD.regionCodeLevelUp === selectedLevelUp &&
            !selectedRegions.value.includes(tD.value);
          tD.isSelectionItSelf =
            tD.regionCodeLevelUp === selectedLevelUp &&
            selectedRegions.value.includes(tD.value);
        });

        treeData = treeData.filter((tD) => {
          return (
            tD.isParentOfSelection ||
            tD.isSelectionItSelf ||
            tD.isSiblingOfSelection
          );
        });

        //set leaf all node with selectedIdLevel
        treeData.forEach((tD) => {
          //set isLeaf
          tD.isLeaf = tD.regionIdLevel === selectedIdLevel;
          tD.selectable =
            regionsSelectionMode === "selection"
              ? tD.isSiblingOfSelection || tD.isSelectionItSelf
              : false;
          tD.disabled =
            regionsSelectionMode === "selection"
              ? !tD.isSiblingOfSelection && !tD.isSelectionItSelf
              : true;
        });
        break;
      case "reference":
        treeData = _.cloneDeep(treeDataRegions);
        break;
      default:
        errorMsg = getErrorMsgForTreeDataMemo();
        console.log(errorMsg);
        throw new Error(errorMsg);
    }

    return treeData;
  };

  const getTreeDataMemoForViewMode = (treeDataArg) => {
    let treeData = _.cloneDeep(treeDataArg);
    let errorMsg;
    let selectedIdLevel, selectedLevelUp;
    switch (selectedRegions.mode) {
      case "selection":
        //filter treeData by siblings and parents
        const selectedTD = getTDByValue(selectedRegions.value[0], treeData);
        selectedIdLevel = selectedTD?.regionIdLevel;
        selectedLevelUp = selectedTD?.regionCodeLevelUp;

        //update selection hierarchy categories
        treeData.forEach((tD) => {
          tD.isParentOfSelection =
            tD.regionIdLevel < selectedIdLevel &&
            selectedLevelUp.startsWith(tD.regionCode);
          tD.isSelectionItSelf =
            tD.regionCodeLevelUp === selectedLevelUp &&
            selectedRegions.value.some((value) => tD.value === value);
          tD.isSiblingOfSelection =
            tD.regionCodeLevelUp === selectedLevelUp &&
            selectedRegions.value.every((value) => tD.value !== value);
        });

        //set leaf all node with selectedIdLevel
        treeData.forEach((tD) => {
          //set isLeaf
          tD.isLeaf = tD.regionIdLevel === selectedIdLevel;
          tD.selectable =
            regionsSelectionMode === "selection"
              ? tD.isSiblingOfSelection || tD.isSelectionItSelf
              : false;
          tD.disabled =
            regionsSelectionMode === "selection"
              ? !tD.isSiblingOfSelection && !tD.isSelectionItSelf
              : true;
        });
        break;
      case "reference":
        selectedIdLevel = Math.max(...treeData.map((tD) => tD.regionIdLevel));
        selectedLevelUp = treeData.find(
          (tD) => tD.regionIdLevel === selectedIdLevel
        ).regionCodeLevelUp;

        //update selection hierarchy categories
        treeData.forEach((tD) => {
          tD.isParentOfSelection = null;
          tD.isSelectionItSelf = null;
        });

        //set leaf all node with selectedIdLevel
        treeData.forEach((tD) => {
          //set isLeaf
          tD.isLeaf = tD.regionIdLevel === selectedIdLevel;
          tD.selectable = tD.regionCodeLevelUp === selectedLevelUp;
          tD.disabled = tD.regionCodeLevelUp !== selectedLevelUp;
        });
        break;
      default:
        errorMsg = getErrorMsgForTreeDataMemo();
        console.log(errorMsg);
        throw new Error(errorMsg);
    }

    return treeData;
  };

  const treeDataRegionsMemoRef = useRef();
  const treeDataRegionsMemo = useMemo(
    () => {
      if (!treeDataRegions) {
        return [
          {
            title: <LoadingTreeDataRegionsMemo />,
            value: "initialValue",
            selectable: false,
          },
        ];
      }

      let treeDataMemo;

      switch (parent) {
        case "DataAnalysisForm":
          if (!treeDataRegionsMemoRef.current) {
            treeDataMemo = _.cloneDeep(treeDataRegions);
          } else {
            const treeDataRegionsMemoValues = _.orderBy(
              treeDataRegionsMemoRef.current,
              ["value"]
            ).map((tD) => tD.value);
            const treeDataRegionValues = _.orderBy(treeDataRegions, [
              "value",
            ]).map((tD) => tD.value);
            if (_.isEqual(treeDataRegionsMemoValues, treeDataRegionValues)) {
              treeDataMemo = _.cloneDeep(treeDataRegionsMemoRef.current);
            } else {
              treeDataMemo = _.cloneDeep(treeDataRegions);
            }
          }

          switch (formMode) {
            case "new":
              treeDataMemo = getTreeDataMemoForNewMode(treeDataMemo);
              break;
            case "edit":
              treeDataMemo = getTreeDataMemoForEditMode(treeDataMemo);
              break;
            case "view":
              treeDataMemo = getTreeDataMemoForViewMode(treeDataMemo);
              break;
            default:
              const errorMsg = `Something went wrong! 'formMode' value: ${formMode}, is not implemented!`;
              throw new Error(errorMsg);
          }
          break;
        default:
          treeDataMemo = _.cloneDeep(treeDataRegions);
      }

      treeDataRegionsMemoRef.current = treeDataMemo;
      return treeDataMemo;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treeDataRegions, selectedRegions?.mode, regionsSelectionMode]
  );

  const isReadyTreeDataRegionsMemo = useMemo(() => {
    //TODO: add any other condition to indicate if options are loaded
    return !(
      (treeDataRegionsMemo?.length === 1 &&
        treeDataRegionsMemo?.[0]?.value === "initialValue") ||
      treeDataRegionsMemo?.length === 0
    );
  }, [treeDataRegionsMemo]);

  /**
   *
   * @param {*} idLevelMax
   * @param {*} filters
   * @param {*} idLevel
   * @param {*} treeData
   * @returns
   */
  async function getTreeDataAsyncRecursively(
    idLevelMax,
    filters,
    idLevel = 0,
    treeData = []
  ) {
    if (idLevel > idLevelMax) {
      return treeData;
    } else {
      return fetchRegionsData(
        idLevel,
        referenceRegionCodeFilters.returnCase,
        filters,
        setFetchRegionsStatus
      )()
        .then((data) => {
          if (data?.count > 0) {
            treeData.push(...data.data);
            return treeData;
          }
        })
        .then((treeData) => {
          return getTreeDataAsyncRecursively(
            idLevelMax,
            filters,
            idLevel + 1,
            treeData
          );
        });
    }
  }

  /**
   * Get actual treeDataRegions (TDR).
   * Filter treeData to keep only the items with regionIdLevel  lesser than geoLevel.idLevelMax.
   * This is necessary to limit the items that can be selected in the tree select component
   * when  dataCollectionMod.levelsField is true.
   * In regions and levels terms this means that when levels are collected then regions
   * that belong to the last available level cannot be collected.
   * @param {*} treeData
   * @returns
   */
  const getActualTDR = (treeData) => {
    const treeData_ = _.cloneDeep(treeData);
    if (formMode === "view") {
      return treeData_;
    } else if (dataCollectionMode.levelsField) {
      const filteredTD = treeData_.filter((tD) => {
        return tD.regionIdLevel < tD.geoLevel.idLevelMax;
      });

      filteredTD.forEach((tD) => {
        tD.isLeaf = tD.regionIdLevel === tD.geoLevel.idLevelMax - 1;
      });
      return filteredTD;
    } else {
      return treeData_;
    }
  };

  const fetchTreeDataRegions = (idLevelMax, filters) => {
    getTreeDataAsyncRecursively(idLevelMax, filters).then((treeData) => {
      setTreeDataRegions(() => {
        const treeData_ = getActualTDR(treeData);
        return treeData_;
      });
    });
  };

  const loadRegionsDataLoopCountRef = useRef(0);
  const loadRegionsData = async (treeNode) => {
    try {
      //exclude from loadingRegionsData any treeNode that is not selectable and disabled
      if (!treeNode.selectable && treeNode.disabled) {
        return;
      }

      //initializing count
      loadRegionsDataLoopCountRef.current = 0;

      // Perform your asynchronous operation
      const idLevel = treeNode.regionIdLevel + 1;
      const filters = {
        ...fetchFilter,
        loadRegionsFilter: [
          {
            regionCode: treeNode.regionCode,
            regionCodeLevelUp: treeNode.regionCodeLevelUp,
            regionIdLevel: treeNode.regionIdLevel,
            regionName: treeNode.regionName,
          },
        ],
        strategy: "children",
      };

      const data = await fetchRegionsData(
        idLevel,
        referenceRegionCodeFilters.returnCase,
        filters,
        setFetchRegionsStatus
      )();

      loadRegionsDataLoopCountRef.current =
        loadRegionsDataLoopCountRef.current + 1;

      //this line is to avoid the loop that appears after the await line above.
      //TODO: find why this loop is produced
      if (loadRegionsDataLoopCountRef.current > 1) return;

      setTreeDataRegions((prevTD) => {
        let newTD = _.uniqBy([...prevTD, ...data.data], "value");
        let newTDOrdered = _.orderBy(newTD, ["title"], ["asc"]);

        const updated = getActualTDR(newTDOrdered);
        return updated;
      });
    } catch (error) {
      // Handle any errors
      console.error("Error loading data in FormItemsLocationsGlobal:", error);
      throw error;
    }
  };

  const handleLoadRegionsData = async (treeNode) => {
    try {
      await loadRegionsData(treeNode);
    } catch (error) {
      console.error("Error handling loadRegionsData:", error);
    }
  };

  const handleTreeExpandRegions = (treeExpandedArg) => {
    setTreeExpandedKeysRegions(treeExpandedArg);
  };

  //fetchTreeDataRegions
  useEffect(
    function useEffect_fetchTreeDataRegions() {
      if (fetchFilter === null) return;

      let idLevelMax = null;
      let filters = null;

      switch (parent) {
        case "DataAnalysisForm":
          if (formMode === "new") {
            idLevelMax = referenceRegionCodeFilters.values[0].regionIdLevel;
            filters = {
              ...fetchFilter,
            };
          } else if (formMode === "edit") {
            //edit analysis
            //regions
            if (selectedRegions.mode === "selection") {
              const regionJSONValues = configEdit?.locations?.regions;
              const regionJSONValue = regionJSONValues[0];
              if (regionJSONValue) {
                const region = JSON.parse(regionJSONValue);
                const regionIdLevel = region.regionIdLevel;
                idLevelMax = regionIdLevel;
                filters = {
                  ...fetchFilter,
                  editModeRegionsFilter: regionJSONValues.map(
                    (regionJSONValue) => JSON.parse(regionJSONValue)
                  ),
                  strategy: "siblings_parents_uncles",
                };
              } else if (selectedRegions.mode === "reference") {
                idLevelMax = referenceRegionCodeFilters.values[0].regionIdLevel;
                filters = {
                  ...fetchFilter,
                };
              }
            }
          } else if (formMode === "view") {
            //view analysis
            //regions
            const regionJSONValues = configEdit?.locations?.regions;
            const regionJSONValue = regionJSONValues?.[0];
            if (regionJSONValue) {
              const region = JSON.parse(regionJSONValue);
              const regionIdLevel = region.regionIdLevel;
              idLevelMax = regionIdLevel;

              filters = {
                ...fetchFilter,
                viewModeRegionsFilter: regionJSONValues.map((regionJSONValue) =>
                  JSON.parse(regionJSONValue)
                ),
              };
            } else {
              idLevelMax = referenceRegionCodeFilters.values[0].regionIdLevel;
              filters = {
                ...fetchFilter,
              };
            }
          }
          break;
        case "SurveyFormsData":
          if (formMode === "new") {
            idLevelMax = referenceRegionCodeFilters.values[0].regionIdLevel;
            filters = {
              ...fetchFilter,
            };
          }
          break;
        default:
          if (formMode === "new") {
            idLevelMax = 0;
            filters = {
              ...fetchFilter,
            };
          } else if (formMode === "edit" && regionsFieldInitialValue) {
            const selectedRegion_ = JSON.parse(regionsFieldInitialValue);
            idLevelMax = selectedRegion_.regionIdLevel;
            filters = {
              ...fetchFilter,
              editModeRegionsFilter: [selectedRegion_], //must be an array
              strategy: "siblings_parents_uncles",
            };
          }
      }

      if (idLevelMax === null || filters === null) return;

      fetchTreeDataRegions(idLevelMax, filters);
    },
    //eslint-disable-next-line
    [fetchFilter, regionsFieldInitialValue]
  );

  //useEffect_update_regions_and_selectedRegions
  useEffect(
    function useEffect_update_selectedRegions() {
      if (treeDataRegionsMemo[0].value === "initialValue") return;
      //this useEffect is to handle regionsSelectionMode change and
      //setting the corresponding values for regions field.
      if (
        regionsSelectionMode === "all" &&
        selectedRegions?.mode === "selection"
      ) {
        const allValues = treeDataRegionsMemo
          .filter((tD) => tD.isSiblingOfSelection || tD.isSelectionItSelf)
          .map((tD) => tD.value);
        form.setFieldValue(getFieldPathShort("regions"), allValues);

        setSelectedRegions({
          value: allValues,
          mode: "selection",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionsSelectionMode, selectedRegions?.mode]
  );

  //useEffect_update_regions_byUserInteraction
  useEffect(
    function useEffect_update_regions_byUserInteraction() {
      if (!changedField) return;

      if (isFieldChangedFunction("regions")) {
        setSelectedRegions({
          value: changedField.value,
          mode: changedField.value?.length > 0 ? "selection" : "reference",
        });
      }
    },
    //eslint-disable-next-line
    [changedField]
  );

  //useEffect_update_treeExpandedKeysRegions
  useEffect(
    function useEffect_update_treeExpandedKeysRegions() {
      if (!isReadyTreeDataRegionsMemo) return;
      switch (parent) {
        case "DataAnalysisForm":
          let errorMsg;
          switch (formMode) {
            case "new":
              switch (selectedRegions.mode) {
                case "selection":
                  setTreeExpandedKeysRegions(() => {
                    const newExpandedKeys = treeDataRegionsMemo
                      .filter((tD) => tD.isParentOfSelection)
                      .map((tD) => tD.value);

                    return newExpandedKeys;
                  });
                  break;
                case "reference":
                  setTreeExpandedKeysRegions(() => {
                    const newExpandedKeys = treeDataRegionsMemo
                      .filter((tD) => tD.isParentOfReference)
                      .map((tD) => tD.value);

                    return newExpandedKeys;
                  });
                  break;
                default:
                  errorMsg = getErrorMsgForTreeDataMemo();
                  console.log(errorMsg);
                  throw new Error(errorMsg);
              }
              break;
            case "edit":
              switch (selectedRegions.mode) {
                case "selection":
                  setTreeExpandedKeysRegions(() => {
                    const newExpandedKeys = treeDataRegionsMemo
                      .filter((tD) => tD.isParentOfSelection)
                      .map((tD) => tD.value);

                    return newExpandedKeys;
                  });
                  break;
                case "reference":
                  setTreeExpandedKeysRegions(() => {
                    const newExpandedKeys = treeDataRegionsMemo
                      .filter((tD) => tD.isParentOfReference)
                      .map((tD) => tD.value);

                    return newExpandedKeys;
                  });
                  break;
                default:
                  errorMsg = getErrorMsgForTreeDataMemo();
                  console.log(errorMsg);
                  throw new Error(errorMsg);
              }
              break;
            case "view":
              switch (selectedRegions.mode) {
                case "selection":
                  setTreeExpandedKeysRegions(() => {
                    const newExpandedKeys = treeDataRegionsMemo
                      .filter((tD) => tD.isParentOfSelection)
                      .map((tD) => tD.value);

                    return newExpandedKeys;
                  });
                  break;
                case "reference":
                  setTreeExpandedKeysRegions(() => {
                    const selectedIdLevel = Math.max(
                      ...treeDataRegionsMemo.map((tD) => tD.regionIdLevel)
                    );
                    const newExpandedKeys = treeDataRegionsMemo
                      .filter((tD) => tD.regionIdLevel < selectedIdLevel)
                      .map((tD) => tD.value);

                    return newExpandedKeys;
                  });
                  break;
                default:
                  errorMsg = getErrorMsgForTreeDataMemo();
                  console.log(errorMsg);
                  throw new Error(errorMsg);
              }
              break;
            default:
              errorMsg = `Something went wrong! 'formMode' value: ${formMode}, is not implemented!`;
              throw new Error(errorMsg);
          }
          break;
        case "SurveyFormsData":
          if (formMode === "new") {
            setTreeExpandedKeysRegions(() => {
              const newKeys = treeDataRegionsMemo
                .filter((tD) => tD.isParentOfReference)
                .map((tD) => tD.value);

              return newKeys;
            });
          }
          break;
        default:
          if (formMode === "edit") {
            setTreeExpandedKeysRegions(() => {
              const initialRegion = JSON.parse(regionsFieldInitialValue);
              const initialRegionCodeLevelUp = initialRegion.regionCodeLevelUp;
              const newKeys = treeDataRegionsMemo
                .filter((tD) =>
                  initialRegionCodeLevelUp.startsWith(tD.regionCode)
                )
                .map((tD) => tD.value);

              return newKeys;
            });
          }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReadyTreeDataRegionsMemo]
  );

  const [idLevelMaxRegions, setIdLevelMaxRegions] = useState();

  //useEffect_setIdLevelMax
  useEffect(
    function useEffect_setIdLevelMax() {
      if (!isReadyTreeDataRegionsMemo) return;
      setIdLevelMaxRegions(() => {
        const idLevelMaxValues = treeDataRegionsMemo.map(
          (tD) => tD.geoLevel.idLevelMax
        );
        const idLevelMax = Math.max(...idLevelMaxValues);

        return idLevelMax;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treeDataRegionsMemo, isReadyTreeDataRegionsMemo]
  );

  //useEffect_setDropdownMenu_minWidth
  const dropDownMenuMinWidth = useMemo(() => {
    switch (idLevelMaxRegions) {
      case 0:
        return "100px";
      case 1:
      case 2:
      case 3:
        return "250px";
      case 4:
      case 5:
        return "300px";
      case 6:
        return "450px";
      case 7:
      case 8:
        return "500px";
      case 9:
        return "550px";
      default:
        return "650px";
    }
  }, [idLevelMaxRegions]);

  // ===========================
  // Levels
  // ===========================

  //hooks
  const [selectedLevels, setSelectedLevels] = useState();
  const [treeDataLevels, setTreeDataLevels] = useState(null);
  const treeDataLevelsMemoRef = useRef();
  const treeDataLevelsMemo = useMemo(() => {
    if (!dataCollectionMode?.levelsField) return null;
    if (!treeDataLevels) return null;

    //set treeDataLevels_
    let treeDataLevels_;
    const prevLevels = treeDataLevelsMemoRef.current
      ? _.orderBy(treeDataLevelsMemoRef.current, ["value"]).map(
          (lV) => lV.value
        )
      : null;
    const currentLevels = _.orderBy(treeDataLevels, ["value"]).map(
      (lV) => lV.value
    );

    if (_.isEqual(prevLevels, currentLevels)) {
      treeDataLevels_ = _.cloneDeep(treeDataLevelsMemoRef.current);
    } else {
      treeDataLevels_ = _.cloneDeep(treeDataLevels);
    }

    //relation: the selectable and disabled levels depend on the idLevel of selected regions
    if (selectedRegions.mode === "selection") {
      //only one regionIdLevel for all selectedRegions.value items must exist
      const selectedRegionsIdLevels = selectedRegions.value.map(
        (regionJSON) => {
          return JSON.parse(regionJSON).regionIdLevel;
        }
      );
      const uniqueRegionIdLevel = _.uniq(selectedRegionsIdLevels);
      if (!uniqueRegionIdLevel?.length === 1) {
        const errorMsg =
          `All items in 'selectedRegions.value' must have the same ` +
          `and unique value for the key 'regionsIdLevel'.`;
        throw new Error(errorMsg);
      }

      //selecting the first item, because all items have the same regionIdLevel
      const selectedIdLevel = uniqueRegionIdLevel[0];

      treeDataLevels_.forEach((tD) => {
        tD.selectable = tD.idLevel > selectedIdLevel;
        tD.disabled = tD.idLevel <= selectedIdLevel;
      });
    } else if (selectedRegions.mode === "reference") {
      treeDataLevels_ = _.cloneDeep(treeDataLevels);
    }

    //relation: the selectable and disabled levels in formMode "view" depend on
    //the selected values of levels in formMode "edit"
    if (formMode === "view" && treeDataLevels_) {
      const levelsEditValues = configEdit.locations.levels;

      if (levelsEditValues?.length > 0) {
        treeDataLevels_.forEach((tD) => {
          tD.selectable = levelsEditValues.some((lV) => lV === tD.value);
          tD.disabled = !levelsEditValues.some((lV) => lV === tD.value);
        });
      }
    }

    treeDataLevelsMemoRef.current = treeDataLevels_;
    return treeDataLevels_;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    treeDataLevels,
    selectedRegions,
    dataCollectionMode?.levelsField,
    formMode,
  ]);

  const isReadyTreeDataLevelsMemo = useMemo(() => {
    //TODO: add any other condition to indicate if options are loaded
    return !(
      (treeDataRegionsMemo?.length === 1 &&
        treeDataRegionsMemo?.[0]?.value === "initialValue") ||
      treeDataRegionsMemo?.length === 0
    );
  }, [treeDataRegionsMemo]);

  //useEffect_setSelectedLevels
  useEffect(
    function useEffect_setSelectedLevels() {
      if (!dataCollectionMode) return;
      if (dataCollectionMode.levelsField) {
        setSelectedLevels(() => {
          let value = null;
          if (formMode === "new") {
            value = [];
          } else if (formMode === "edit") {
            value = configEdit.locations.levels;
          } else if (formMode === "view") {
            value = configView ? configView.locations.levels : [];
          }

          return value;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataCollectionMode]
  );
  //useEffect_fetchTreeDataLevels
  useEffect(
    //useEffect_setTreeDataGeoLevels
    function useEffect_fetchTreeDataLevels() {
      if (!dataCollectionMode?.levelsField) return;
      if (fetchFilter === null) return;

      const filters = {
        referenceRegionsFilter: fetchFilter.referenceRegionsFilter,
        parent: parent,
      };

      fetchLevelsData(
        referenceRegionCodeFilters.returnCase,
        filters,
        setFetchLevelsStatus
      )().then((data) => {
        if (data?.count > 0) {
          setTreeDataLevels(data.data);
        } else {
          setTreeDataLevels();
          const msg = `'treeDataLevels' data were not retrieved from server`;
          console.log(msg);
        }
      });
    },
    //eslint-disable-next-line
    [fetchFilter]
  );
  //useEffect_reset_levels
  useEffect(
    function useEffect_reset_levels() {
      if (selectedRegions.mode === "reference") {
        form.resetFields([getFieldPathShort("levels")]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRegions]
  );
  //useEffect_update_levels_byUserInteraction
  useEffect(
    function useEffect_update_levels_byUserInteraction() {
      if (!changedField) return;

      if (isFieldChangedFunction("levels")) {
        setSelectedLevels(changedField.value);
      }
    },
    //eslint-disable-next-line
    [changedField]
  );
  //useEffect_selectedLevels_state_changed
  useEffect(
    function useEffect_selectedLevels_state_changed() {
      //TODO: keep this code for future expansion of component, but
      //currently it does not do nothing!
    },
    //eslint-disable-next-line
    [selectedLevels]
  );

  // ===========================
  // Global effects
  // ===========================

  //useEffect_resetComponent
  useEffect(
    function useEffect_resetComponent() {
      if (analysisType) {
        if (
          formMode !== "view" &&
          !areAllFormFieldsJustFilledByCodeRef.current
        ) {
          resetComponent("type");
        }
      }
    },
    //eslint-disable-next-line
    [analysisType]
  );

  useEffect(
    function useEffect_resetComponent() {
      if (analysisProject) {
        if (
          formMode !== "view" &&
          !areAllFormFieldsJustFilledByCodeRef.current
        ) {
          resetComponent("projects");
        }
      }
    },
    //eslint-disable-next-line
    [analysisProject]
  );

  // ===========================
  // debug section
  // ===========================

  const debugVars = [
    {
      name: "formMode",
      value: formMode,
      dependencies: [formMode],
      shouldLog: true,
    },
    {
      name: "parent",
      value: parent,
      dependencies: [parent],
      shouldLog: true,
    },
    {
      name: "analysisType",
      value: analysisType,
      dependencies: [analysisType],
      shouldLog: true,
    },
    {
      name: "regionsFieldInitialValue",
      value: regionsFieldInitialValue,
      dependencies: [regionsFieldInitialValue],
      shouldLog: !["DataAnalysisForm"].includes(parent),
    },
    {
      name: "referenceGeoAreas",
      value: referenceGeoAreas,
      dependencies: [referenceGeoAreas],
      shouldLog: true,
    },
    {
      name: "referenceRegions",
      value: referenceRegions,
      dependencies: [referenceRegions],
      shouldLog: true,
    },
    {
      name: "referenceRegionCodeFilters",
      value: referenceRegionCodeFilters,
      dependencies: [referenceRegionCodeFilters],
      shouldLog: true,
    },
    {
      name: "fetchFilter",
      value: fetchFilter,
      dependencies: [fetchFilter],
      shouldLog: true,
    },
    {
      name: "treeDataLevels",
      value: treeDataLevels,
      dependencies: [treeDataLevels],
      shouldLog: dataCollectionMode?.levelsField,
    },
    {
      name: "treeDataLevelsMemo",
      value: treeDataLevelsMemo,
      dependencies: [treeDataLevelsMemo],
      shouldLog: dataCollectionMode?.levelsField,
    },
    {
      name: "treeDataRegions",
      value: treeDataRegions,
      dependencies: [treeDataRegions],
      shouldLog: true,
    },
    {
      name: "treeDataRegionsMemo",
      value: treeDataRegionsMemo,
      dependencies: [treeDataRegionsMemo],
      shouldLog: true,
    },
    {
      name: "isReadyTreeDataRegionsMemo",
      value: isReadyTreeDataRegionsMemo,
      dependencies: [isReadyTreeDataRegionsMemo],
      shouldLog: true,
    },
    {
      name: "idLevelMaxRegions",
      value: idLevelMaxRegions,
      dependencies: [idLevelMaxRegions],
      shouldLog: true,
    },
    {
      name: "selectedLevels",
      value: selectedLevels,
      dependencies: [selectedLevels],
      shouldLog: dataCollectionMode?.levelsField,
    },
    {
      name: "selectedRegions",
      value: selectedRegions,
      dependencies: [selectedRegions],
      shouldLog: true,
    },
    {
      name: "regionsSelectionMode",
      value: regionsSelectionMode,
      dependencies: [regionsSelectionMode],
      shouldLog: true,
    },
    {
      name: "treeExpandedKeysRegions",
      value: treeExpandedKeysRegions,
      dependencies: [treeExpandedKeysRegions],
      shouldLog: true,
    },
    {
      name: "fetchRegionsStatus",
      value: fetchRegionsStatus,
      dependencies: [fetchRegionsStatus],
      shouldLog: true,
    },
    {
      name: "fetchLevelsStatus",
      value: fetchLevelsStatus,
      dependencies: [fetchLevelsStatus],
      shouldLog: dataCollectionMode?.levelsField,
    },
    {
      name: "dataCollectionMode",
      value: dataCollectionMode,
      dependencies: [dataCollectionMode],
      shouldLog: true,
    },
  ];

  useDebug({ vars: debugVars, componentName: componentName });

  // ===========================
  // jsx logic
  // ===========================

  //form fields
  const divider =
    formMode !== "view" ? (
      <Form.Item wrapperCol={{ span: 17 }}>
        <Divider
          style={{ borderTopColor: antDividerBorderTopColor }}
          orientation={"left"}
        >
          {intl.formatMessage({
            id: "label.FormItemsLocations.divider",
          })}
        </Divider>
      </Form.Item>
    ) : null;

  const regionsFields = dataCollectionMode?.regionsFields ? (
    <Form.Item>
      {!isReadyTreeDataRegionsMemo ? (
        <Form.Item
          label={intl.formatMessage({
            id: "label.FormItemsLocations.GeoRegions.Regions",
          })}
          name={getFieldPathShort("dummy_regions")}
          initialValue={undefined}
          style={{ marginBottom: 0 }}
          rules={[
            {
              required: formMode === "view",
              message: intl.formatMessage({
                id: "msg.input-required.Analysis.Locations.GeoRegions.Regions",
              }),
            },
          ]}
        >
          <TreeSelect
            treeDataSimpleMode
            style={{ width: "100%" }}
            allowClear={true}
            placeholder={
              !isReadyTreeDataRegionsMemo ? (
                <LoadingTreeDataRegionsMemo />
              ) : null
            }
            multiple={true}
            treeData={[]}
          />
        </Form.Item>
      ) : null}
      <Form.Item
        label={intl.formatMessage({
          id: "label.FormItemsLocations.GeoRegions.Regions",
        })}
        name={getFieldPathShort("regions")}
        initialValue={undefined}
        rules={[
          {
            required: formMode === "view",
            message: intl.formatMessage({
              id: "msg.input-required.Analysis.Locations.GeoRegions.Regions",
            }),
          },
        ]}
        style={{ marginBottom: 0 }}
        hidden={!isReadyTreeDataRegionsMemo}
      >
        <TreeSelect
          treeDataSimpleMode
          style={{ width: "100%" }}
          showSearch
          allowClear={true}
          placeholder={
            !isReadyTreeDataRegionsMemo ? (
              <LoadingTreeDataRegionsMemo />
            ) : (
              intl.formatMessage({
                id:
                  formMode === "view"
                    ? "label.FormItemsLocations.GeoRegions.Regions.TreeSelect.placeholder.view"
                    : "label.FormItemsLocations.GeoRegions.Regions.TreeSelect.placeholder",
              })
            )
          }
          multiple={true}
          //relation: conditions when loadData is null or not
          loadData={
            selectedRegions.mode === "selection" ||
            formMode === "view" ||
            fetchFilter === null
              ? null
              : handleLoadRegionsData
          }
          treeData={treeDataRegionsMemo}
          treeDefaultExpandAll={false}
          onTreeExpand={handleTreeExpandRegions}
          treeExpandedKeys={treeExpandedKeysRegions}
          treeNodeFilterProp={"title"}
          maxTagCount={4}
        />
      </Form.Item>
      <Form.Item
        name={getFieldPathShort("regionsSelectionMode")}
        style={{ marginBottom: 0, marginTop: 0 }}
        initialValue={"selection"}
      >
        <Radio.Group>
          <Radio value={"all"}>
            {intl.formatMessage({
              id: "label.FormItemsLocations.GeoRegions.selectModeRegions.all",
            })}
          </Radio>
          <Radio value={"selection"}>
            {intl.formatMessage({
              id: "label.FormItemsLocations.GeoRegions.selectModeRegions.selection",
            })}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </Form.Item>
  ) : null;

  const levelsField = dataCollectionMode?.levelsField ? (
    <>
      {!isReadyTreeDataLevelsMemo ? (
        <Form.Item
          label={intl.formatMessage({
            id: "label.FormItemsLocations.SubGeoRegions.SubRegions",
          })}
          name={getFieldPathShort("dummy_levels")}
          initialValue={undefined}
          rules={[
            {
              required: formMode === "view",
              message: intl.formatMessage({
                id: "msg.input-required.Analysis.Locations.SubGeoRegions.SubRegions",
              }),
            },
          ]}
        >
          <TreeSelect
            treeDataSimpleMode
            style={{ width: "100%" }}
            placeholder={
              !isReadyTreeDataLevelsMemo ? <LoadingTreeDataLevelsMemo /> : null
            }
            multiple={formMode !== "view"}
            treeData={[]}
          />
        </Form.Item>
      ) : null}
      <Form.Item
        label={intl.formatMessage({
          id: "label.FormItemsLocations.SubGeoRegions.SubRegions",
        })}
        name={getFieldPathShort("levels")}
        initialValue={undefined}
        rules={[
          {
            required:
              formMode === "view" ||
              (formMode !== "view" &&
                form.getFieldValue(getFieldPathShort("regions"))?.length > 0),
            message: intl.formatMessage({
              id: "msg.input-required.Analysis.Locations.SubGeoRegions.SubRegions",
            }),
          },
        ]}
        dependencies={[getFieldPathShort("regions")]}
        hidden={!isReadyTreeDataLevelsMemo}
      >
        <TreeSelect
          treeDataSimpleMode
          style={{ width: "100%" }}
          showSearch
          allowClear={true}
          placeholder={intl.formatMessage({
            id:
              formMode === "view"
                ? "label.FormItemsLocations.SubGeoRegions.SubRegions.TreeSelect.placeholder.view"
                : "label.FormItemsLocations.SubGeoRegions.SubRegions.TreeSelect.placeholder",
          })}
          multiple={formMode !== "view"}
          treeDefaultExpandAll={true}
          treeData={treeDataLevelsMemo}
          treeNodeFilterProp={"title"}
          disabled={(() => {
            const regionsFieldValue = form.getFieldValue(
              getFieldPathShort("regions")
            );
            return !regionsFieldValue || regionsFieldValue?.length === 0;
          })()}
          maxTagCount={"responsive"}
          showCheckedStrategy={TreeSelect.SHOW_ALL}
        />
      </Form.Item>
    </>
  ) : null;

  const geometryAccuracyField = dataCollectionMode?.geometryAccuracyField ? (
    <Form.Item style={formMode === "view" ? { height: "0px" } : null}>
      <GeometryAccuracy
        formItemName={getFieldPathShort("geometryAccuracy")}
        hidden={formMode === "view"}
      />
    </Form.Item>
  ) : null;

  const locationFields = (
    <Form.Item
      label={
        formMode !== "view"
          ? intl.formatMessage({
              id: "label.FormItemsLocations.GeographicalAreas",
            })
          : null
      }
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 13 }}
      style={formMode === "view" ? { marginBottom: "0px" } : null}
    >
      {regionsFields}
      {levelsField}
      {geometryAccuracyField}
    </Form.Item>
  );

  const regionsField = (
    <>
      {!isReadyTreeDataRegionsMemo ? (
        <Form.Item
          label={intl.formatMessage({
            id: "label.region",
          })}
          name={getFieldPathShort("dummy_regions")}
          initialValue={undefined}
          rules={[
            {
              required: required,
              message: intl.formatMessage({
                id: "msg.input-required",
              }),
            },
          ]}
        >
          <TreeSelect
            treeDataSimpleMode
            style={{ width: "100%" }}
            allowClear={true}
            placeholder={
              !isReadyTreeDataRegionsMemo ? (
                <LoadingTreeDataRegionsMemo />
              ) : null
            }
            multiple={false}
            treeData={treeDataRegionsMemo}
          />
        </Form.Item>
      ) : null}
      <Form.Item
        label={intl.formatMessage({
          id: "label.region",
        })}
        name={getFieldPathShort("regions")}
        initialValue={undefined}
        style={style}
        rules={[
          {
            required: required,
            message: intl.formatMessage({
              id: "msg.input-required",
            }),
          },
        ]}
        hidden={!isReadyTreeDataRegionsMemo}
      >
        <TreeSelect
          treeDataSimpleMode
          style={
            ["SurveyFormsData"].includes(parent) ? null : { width: "100%" }
          }
          showSearch
          allowClear={true}
          placeholder={
            !isReadyTreeDataRegionsMemo ? (
              <LoadingTreeDataRegionsMemo />
            ) : (
              intl.formatMessage({
                id: "label.FormItemsLocations.GeoRegions.Regions.TreeSelect.placeholder.regionsField",
              })
            )
          }
          multiple={false}
          loadData={handleLoadRegionsData}
          treeData={treeDataRegionsMemo}
          treeDefaultExpandAll={false}
          onTreeExpand={handleTreeExpandRegions}
          treeExpandedKeys={treeExpandedKeysRegions}
          treeNodeFilterProp={"title"}
          dropdownStyle={
            ["SurveyFormsData"].includes(parent)
              ? { minWidth: dropDownMenuMinWidth }
              : null
          }
        />
      </Form.Item>
    </>
  );

  // ===========================
  // rendering
  // ===========================

  return (
    <Suspense fallback={<Skeleton active={true} />}>
      <div id={"selectLocations"}>
        {["DataAnalysisForm"].includes(parent) ? (
          <>
            {divider}
            {locationFields}
          </>
        ) : (
          <>{regionsField}</>
        )}
      </div>
    </Suspense>
  );
};

export default FormItemsLocationsGlobal;
